import { h, Component, render } from 'preact';
import fonts from '../../styles/fonts.css';
import globalStyles from '../../styles/global-styles.css';

class ShadowWrapper extends Component {
  constructor(props) {
    super(props);
    this.shadowRootRef = null;
    this.containerRef = null;
    this.contentElement = null; // Store content wrapper for re-rendering
  }

  componentDidMount() {
    if (!this.containerRef) return;

    this.shadowRootRef = this.containerRef.attachShadow({ mode: 'open' });

    // Inject styles if not already present
    const fontElementStyles = document.createElement('style');
    fontElementStyles.textContent = fonts;
    this.shadowRootRef.appendChild(fontElementStyles);

    const globalElemementStyles = document.createElement('style');
    globalElemementStyles.textContent = globalStyles;
    this.shadowRootRef.appendChild(globalElemementStyles);

    const styleElement = document.createElement('style');
    styleElement.textContent = this.props.styles;
    this.shadowRootRef.appendChild(styleElement);

    // Create a wrapper div for content inside the shadow DOM
    this.contentElement = document.createElement('div');
    this.shadowRootRef.appendChild(this.contentElement);

    // Initial render of children
    this.renderChildren();
  }

  componentDidUpdate() {
    // Re-render children on updates
    this.renderChildren();
  }

  renderChildren() {
    if (this.contentElement) {
      render(this.props.children, this.contentElement);
    }
  }

  render() {
    return <div ref={(el) => (this.containerRef = el)} />;
  }
}

export default ShadowWrapper;
