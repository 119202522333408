import { h } from 'preact';
import styles from './SharedFooter.styles.css';
import ShadowWrapper from '../ShadowWrapper/ShadowWrapper.jsx';
import useWindowWidth from '../../lib/hooks/useWindowWidth';
import { useEffect, useState, useMemo, useCallback } from 'preact/hooks';
import { chunk } from '../../lib/utils';

const socialIcons = [
  {
    href: 'https://twitter.com/crsrdschurch/',
    id: 'ftr-twitter-account',
    src: 'https://d1tmclqz61gqwd.cloudfront.net/images/twitter.svg',
    title: 'twitter account'
  },
  {
    href: 'https://facebook.com/crsrdschurch/',
    id: 'ftr-facebook-account',
    src: 'https://d1tmclqz61gqwd.cloudfront.net/images/facebook.svg',
    title: 'facebook account'
  },
  {
    href: 'https://youtube.com/crdschurch/',
    id: 'ftr-youtube-account',
    src: 'https://d1tmclqz61gqwd.cloudfront.net/images/youtube.svg',
    title: 'youtube account'
  },
  {
    href: 'https://instagram.com/crsrdschurch/',
    id: 'ftr-instagram-account',
    src: 'https://d1tmclqz61gqwd.cloudfront.net/images/instagram.svg',
    title: 'instagram account'
  },
  {
    href: 'https://www.pinterest.com/CrsrsdsChurch/',
    id: 'ftr-pinterest-account',
    src: 'https://images.ctfassets.net/y3a9myzsdjan/52wya5qWtyIcHLfPOW142o/4e127aaa1a11cc8dd1525c5c3e177bf2/pinterest-round-white-icon.png',
    title: 'pinterest account'
  }
];

const SharedFooter = (props) => {
  let { osanoAdded = false } = props;
  const [data, setData] = useState();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < 768;

  const processData = useCallback(
    async (result) => {
      if (!result) return [];

      const cookieSettings = {
        title: 'Cookie Settings',
        href: '#',
        'automation-id': 'ftr-osano',
        onClick: (event) => {
          event.preventDefault();
          window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
        }
      };

      if (isMobile) {
        const flatData = result.flat(Infinity);
        const chunkedData = chunk(flatData, 4);
        const contactSection = chunkedData[1]?.[3];

        if (contactSection?.title === 'Contact') {
          contactSection.children.push(cookieSettings);
        }

        return chunkedData;
      }

      const contactSection = result?.[4]?.[0];
      if (contactSection?.title === 'Contact') {
        contactSection.children.push(cookieSettings);
      }

      return result;
    },
    [isMobile]
  );

  useEffect(() => {
    const getData = async () => {
      let dataUrl = '';

      if (process.env.CRDS_ENV === 'demo') {
        dataUrl = 'https://crds-data.crossroads.net/shared-footer/demo.json';
      } else {
        dataUrl = 'https://crds-data.crossroads.net/shared-footer/prod.json';
      }

      try {
        const response = await fetch(dataUrl);
        const result = await response.json();
        const processedData = await processData(result);
        setData(processedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    getData();
  }, [isMobile, processData]);

  const loadOsano = useMemo(() => {
    if (osanoAdded) return null;

    const isProduction = process.env.CRDS_ENV === 'prod';
    const isDebug = process.env.OSANO_DEBUG;
    const scriptSrc =
      isProduction && !isDebug
        ? 'https://cmp.osano.com/6olYRSI8uN8V3oE3/ee6703e4-e14a-4e11-bf03-f5b7b37c7061/osano.js'
        : 'https://cmp.osano.com/6olYRSI8uN8V3oE3/65a23ab1-e0ce-4ccd-bc3c-2c8b89cb18f5/osano.js';

    return <script src={scriptSrc} />;
  }, [osanoAdded]);

  const footerElement = (
    <footer>
      <div className="inner">
        {data?.map((array, id) => (
          <div className="column">
            {array.map((subaray) => (
              <div className="subaray">
                <a href={subaray.href} target="_blank" rel="noopener" className="footer-link">
                  <div className="separator"></div>
                  <h6>{subaray.title}</h6>
                </a>
                <ul>
                  {subaray.children.map((child) => (
                    <li>
                      {Object.prototype.hasOwnProperty.call(child, 'href') ? (
                        <a
                          href={child.href}
                          className="footer-link"
                          onClick={child.onClick}
                          data-automation-id={child['automation-id']}
                        >
                          {child.title}
                        </a>
                      ) : (
                        <h6 className="subheading">{child.title}</h6>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="bottom">
        <div className="bottom-separator"></div>
        <div className="bottom-text">
          Crossroads <br />
          Church
        </div>
        <div className="bottom-icons">
          {socialIcons.map((icon) => (
            <a href={icon.href} target="_blank" rel="noopener">
              <div className="icon-container">
                <img src={icon.src} alt={icon.title} width={14} height={14} />
              </div>
            </a>
          ))}
        </div>
        <div className="bottom-text">
          Your Spiritual <br />
          Outfitter
        </div>
      </div>
      {loadOsano}
    </footer>
  );
  return <ShadowWrapper styles={styles}>{footerElement}</ShadowWrapper>;
};

export default SharedFooter;
